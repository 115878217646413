import { toast } from 'react-toastify';
import { fetchProvisionalReceipt, sendNewNfse, sendMultipleNfse, cancelNfse, fetchNfseConcept, fetchDownloadNfse } from './services';
import { setProvisionalReceipt, clearNfWithErrors, setRpsInfo, setIsNfInfo, handleLoading } from './actions';

export const loadProvisionalReceipt = ({
  userId,
  establishmentId,
  page,
  pageSize,
  qp = '',
  extraProps
}) => dispatch => {
  const { startDate, endDate } = extraProps;

  let url = `${userId}/establishment/${establishmentId}/nf/nfse`;

  if(qp) {
    url = `${url}?startDate=${startDate}&endDate=${endDate}&pg=${page}&limit=${pageSize}${qp}`;
  } else {
    url = `${url}?startDate=${startDate}&endDate=${endDate}&pg=${page}&limit=${pageSize}&typeDateTime=paymentDateTime&orderBy=paymentDateTime&sort=desc`;
  }

  if(qp.includes('nfseStatus')) {
    url = `${url}&typeDateTime=paymentDateTime&orderBy=paymentDateTime`;
  }

  dispatch(handleLoading(true));

  return fetchProvisionalReceipt(url)
    .then(({ data }) => dispatch(setProvisionalReceipt(data?.content, data?.total)))
    .finally(() => dispatch(handleLoading(false)));
}

export const resendNfse = (
  userId,
  establishmentId,
  transactionId,
  page,
  pageSize,
  qp,
  extraProps,
  params,
  handleCloseError
) => dispatch => {
  const url = `${userId}/establishment/${establishmentId}/nf/nfse/${transactionId}/send`;

  dispatch(handleLoading(true));

  return sendNewNfse(url, params)
    .then(res => {
      if(+res.data.nfseStatus != 4) {
        toast.success('Nota enviada com sucesso! Aguarde confirmação.');
        dispatch(loadProvisionalReceipt({ userId, establishmentId, page, pageSize, qp, extraProps }));
        handleCloseError();
      }
    })
    .catch(error => {
      if(error?.response?.data?.data?.msg) {
        toast.error(error?.response?.data?.data?.msg);
      }
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const resendMultipleNfse = (userId, establishmentId, sourceIds) => dispatch => {
  dispatch(handleLoading(true));

  return sendMultipleNfse(userId, establishmentId, sourceIds)
    .then(() => {
      toast.success('Notas fiscais reenviadas com sucesso!');
      return Promise.resolve();
    })
    .catch(() => Promise.reject())
    .finally(() => {
      dispatch(clearNfWithErrors());
      dispatch(handleLoading(false));
    });
}

export const sendCancelNfse = (userId, establishmentId, electronicInvoicesUuid, params) => dispatch => {
  dispatch(handleLoading(true));

  const { page, pageSize, qp, extraProps } = params;

  return cancelNfse(userId, establishmentId, electronicInvoicesUuid)
    .then(({ data }) => {
      toast.success(data);
      dispatch(loadProvisionalReceipt({ userId, establishmentId, page, pageSize, qp, extraProps }));
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const loadNfseConcept = (userId, establishmentId, sourceId) => dispatch => {
  dispatch(setIsNfInfo(false));

  return fetchNfseConcept(userId, establishmentId, sourceId)
    .then(({ data }) => {
      dispatch(setIsNfInfo(true));
      dispatch(setRpsInfo(data));
    })
    .catch(() => dispatch(setIsNfInfo(false)));
}

export const loadDownloadNfse = (userId, establishmentId, sourceId) => dispatch => {
  return fetchDownloadNfse(userId, establishmentId, sourceId)
    .then(data => {
      return window.open(
        URL.createObjectURL(
          new Blob([data], {
            type: 'application/pdf',
          })
        )
      );
    })
    .catch(() => toast.error('Houve um problema ao baixar a nota fiscal.'));
}