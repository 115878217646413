import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing }) => ({
  firstGrid: {
    marginTop: 0,
    paddingRight: spacing(2),
    paddingLeft: spacing(2),
  },
  grid: {
    paddingRight: spacing(2),
    paddingLeft: spacing(2),
  },
  infoCard: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: 10,
    padding: 15,
    margin: 20,
    backgroundColor: 'rgb(241, 244, 249)',
    border: '1px solid #38a8d8',
    borderRadius: 10
  },
  infoCardTextTitle: {
		color: '#1E5168',
		fontSize: 15,
    fontWeight: 'bold',
    marginBottom: 10
	},
	infoCardText: {
		color: '#1E5168',
		fontSize: 15
	},
  establishmentsRenderOption: {
    display: 'flex',
    alignItems: 'center'
  }
}));