import { newAPI } from 'services';

export const fetchSupplierList = async (userId, establishmentId, page, qp) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/storage/suppliers?page=${page}${qp}`);
  return response.data;
}

export const fetchSupplierExport = async (userId, establishmentId, qp) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/storage/suppliers/export?${qp}`);
  return response.data;
}