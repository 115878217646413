import React from 'react';
import PropTypes from 'prop-types';

import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';

import { useStyles } from './styles';

const AutocompleteComponent = ({ options, onChange, value }) => {
  const classes = useStyles({ value });

  const optionValue = () => {
    if (options.length > 0) {
      if (value) {
        return options.filter(opt => opt.value === value)[0];
      }

      return options[0];
    }

    return {}
  }

  const filterOptions = (options, state) => {
    const OPTIONS_LIMIT = 500;
    const defaultFilterOptions = createFilterOptions();

    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  }

  return(
    <Autocomplete
      id="autocomplete"
      openOnFocus
      disableClearable
      options={options}
      onChange={onChange}
      value={optionValue()}
      classes={classes}
      fullWidth
      getOptionLabel={option => option.label}
      filterOptions={filterOptions}
      renderInput={params => (
        <TextField
          {...params}
          multiline
          variant="outlined"
        />
      )}
      renderOption={props => {
        const { label } = props;

        return(
          <span
            {...props}
            style={{ color: '#1E5168', padding: 10, width: '100%' }}
          >
            {label}
          </span>
        );
      }}
    />
  );
}

AutocompleteComponent.defaultProps = {
  options: [],
  value: ''
}

AutocompleteComponent.propTypes = {
  options: PropTypes.arrayOf(),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export { AutocompleteComponent };