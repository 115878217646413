import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Container from '@material-ui/core/Container';
import Button from '../../../components/Button';
import { Formik, Form } from 'formik';

import { Basic, Storage, Consumption, Identifiers } from './steps';

import { setProductBasic, setProductStorage, setIsConsumptionModified } from '../store/actions';
import { loadProduct, upsertProduct, insertProductIdentifiers } from '../store/thunk';
import { insertServiceProduct } from '../../ProductsRegistration/store/thunk';
import { loadProductsFilters } from 'pages/Products/store/thunk';

import { basicValidations } from './steps/Basic/validations';
import { storageValidations } from './steps/Storage/validations';
import { consumptionValidations } from './steps/Consumption/validations';

import useConfirm from 'helpers/hooks/useConfirm';

import { useStyles } from './styles';

export default function ProductsUseAndSellRegistration() {
  const dispatch = useDispatch();

  const history = useHistory();

  const classes = useStyles();

  const [ConfirmModal, confirm] = useConfirm(
    'Edição de serviço com vinculo',
    'Você editou um ou mais campos de um vinculo de serviço com o produto. Deseja seguir sem persistir a edição?'
  );

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { isLoading, isConsumptionModified } = useSelector(state => state.productsUseAndSellRegistration);
  const { productUuid } = useSelector(state => state.productsUseAndSellRegistration);
  const { storageProductUuid } = useSelector(state => state.productsUseAndSellRegistration.basicAndStorageResponse);
  const { storages, productTypes, measurementUnits } = useSelector(state => state.products.filters);
  const submitBasic = useSelector(state => state.productsUseAndSellRegistration.basic);
  const submitStorage = useSelector(state => state.productsUseAndSellRegistration.storage);
  const submitConsumption = useSelector(state => state.productsUseAndSellRegistration.consumption);
  const submitIdentifiers = useSelector(state => state.productsUseAndSellRegistration.identifiers);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isNextButtonClickedOnServiceConsume, setIsNextButtonClickedOnServiceConsume] = useState(false);

  const isEdit = history.location.pathname == '/editar-produto-consumo-e-venda';

  useEffect(() => {
    if(productUuid) {
      dispatch(loadProduct(userId, establishmentId, productUuid));
    }
  }, [productUuid]);

  useEffect(() => {
    dispatch(setProductStorage({ ...submitStorage, storageId: storages[0]?.id || '' }));
  }, [storages]);

  useEffect(() => {
    dispatch(setIsConsumptionModified(false));
    dispatch(loadProductsFilters(userId, establishmentId));
  }, []);

  const configurations = [
    {
      index: 0,
      id: 'basic',
      title: 'Básicas',
      component: Basic,
      validations: basicValidations
    },
    {
      index: 1,
      id: 'storage',
      title: 'Estoque',
      component: Storage,
      validations: storageValidations
    },
    {
      index: 2,
      id: 'consumption',
      title: 'Consumo Serviço',
      component: Consumption,
      validations: isNextButtonClickedOnServiceConsume ? null : consumptionValidations
    },
    {
      index: 3,
      id: 'identifiers',
      title: 'Identificadores',
      component: Identifiers,
      validations: null
    }
  ];

  const currentConfiguration = configurations.find(config => config.index == currentIndex) || {};

  const handleConsumptionModified = async () => {
    if(isConsumptionModified) {
      const confirmEdit = await confirm();

      if(!confirmEdit) {
        setIsNextButtonClickedOnServiceConsume(false);
        return;
      }

      return handleNextStep();
    }
  }

  const handleSubmit = async params => {
    if(currentConfiguration.id == 'basic') {
      dispatch(setProductBasic(params));
      return handleNextStep();
    }

    if(currentConfiguration.id == 'storage') {
      dispatch(setProductStorage(params));

      const formattedParams = {
        ...submitBasic,
        ...params,
        areasOfUse: submitBasic?.areasOfUse?.length > 0 ? submitBasic?.areasOfUse : '[]'
      }

      dispatch(upsertProduct(userId, establishmentId, productUuid, formattedParams))
        .then(() => handleNextStep());
    }

    if(currentConfiguration.id == 'consumption') {
      const { name, netContent, productTypeId } = submitBasic;
      const { serviceId, toBeUsedContent, measurementUnitIdConsumptionService } = params?.consumptionByService[params?.consumptionByService?.length - 1];

      const productTypeDescription = productTypes?.find(product => +product.id == +productTypeId)?.description || '';
      const measurementUnitName = measurementUnits?.find(unit => +unit.id == +measurementUnitIdConsumptionService)?.description || '';

      const consumptionParams = {
        storageProductUuid,
        storageProductName: name,
        measurementUnitId: measurementUnitIdConsumptionService,
        measurementUnitName,
        productTypeId: !!productTypeId ? productTypeId : 0,
        productTypeDescription,
        netContent,
        serviceId,
        toBeUsedContent
      }

      const isConsumptionValid = !!serviceId && !!toBeUsedContent && !!measurementUnitIdConsumptionService;

      if(isNextButtonClickedOnServiceConsume) {
        setIsNextButtonClickedOnServiceConsume(false);

        if(!isConsumptionValid) {
          return handleNextStep();
        }

        dispatch(insertServiceProduct(userId, establishmentId, consumptionParams))
          .then(() => handleNextStep());

        return;
      }

      dispatch(insertServiceProduct(userId, establishmentId, consumptionParams));
    }

    if(currentConfiguration.id == 'identifiers') {
      dispatch(insertProductIdentifiers(userId, establishmentId, productUuid, { ...params, storageProductUuid: productUuid }))
        .then(() => history.push('/produtos'));
    }
  }

  const handlePreviousStep = () => {
    if(currentIndex == 0) {
      return history.push('/produtos');
    }

    setCurrentIndex(currentIndex - 1);
  }

  const handleNextStep = () => {
    if(currentIndex == configurations[configurations.length - 1].index) {
      return;
    }

    setCurrentIndex(currentIndex + 1);
  }

  const getInitialValues = () => {
    if(currentConfiguration.id == 'basic') {
      return submitBasic;
    }

    if(currentConfiguration.id == 'storage') {
      return submitStorage;
    }

    if(currentConfiguration.id == 'consumption') {
      return submitConsumption;
    }

    if(currentConfiguration.id == 'identifiers') {
      return submitIdentifiers;
    }

    return {}
  }

  return(
    <Grid>
      <ConfirmModal />
      <Stepper
        alternativeLabel
        activeStep={currentIndex}
        nonLinear={isEdit}
        style={{ background: 'transparent', padding: 0, paddingBottom: 24 }}
      >
        {configurations.map((nav, index) => (
          <Step key={nav.id}>
            <StepButton
              completed={currentIndex > index}
              onClick={() => setCurrentIndex(index)}
            >
              {nav.title}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Grid>
        <Formik
          enableReinitialize
          initialValues={getInitialValues()}
          validationSchema={currentConfiguration.validations}
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          {({ ...formikProps }) => {
            return(
              <Form>
                {(currentIndex == currentConfiguration.index) && (
                  <currentConfiguration.component formikProps={formikProps} />
                )}
                <Grid className={classes.footer}>
                  <Container>
                    <Grid container justify="space-between">
                      <Button
                        loading={isLoading}
                        color="secondary"
                        type="button"
                        onClick={handlePreviousStep}
                      >
                        Voltar
                      </Button>
                      <Button
                        loading={isLoading}
                        color="success"
                        type={(currentConfiguration.id == 'consumption' && isConsumptionModified) ? 'button' : 'submit'}
                        onClick={() => {
                          if(currentConfiguration.id == 'consumption') {
                            if(isConsumptionModified) {
                              return handleConsumptionModified();
                            }

                            setIsNextButtonClickedOnServiceConsume(true);
                          }
                        }}
                      >
                        Avançar
                      </Button>
                    </Grid>
                  </Container>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}