import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { concat } from 'ramda';
import { uniqBy } from 'lodash';

import { Paper, Grid, Typography, IconButton, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Checkbox, CircularProgress, FormControlLabel } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Filter from '../../../components/Filter/components';
import FilterMobile from '../../../components/FilterMobile/components';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import ServiceOrder from '../../../components/ServiceOrder/components';
import Invoice from '../../../components/Invoice';
import InvoiceServiceContract from '../../../components/InvoiceServiceContract';
import ButtonConfirmation from '../../../components/ButtonConfirmation';
import Receipt from '../../Transactions/components/Receipt';
import Payment from '../../Transactions/components/Payment';
import ProvisionalReceiptError from './ProvisionalReceiptError';
import LoaderLabel from 'components/LoaderLabel';
import WarningMessage from 'components/WarningMessage';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faExclamationCircle, faTimesCircle, faDownload } from '@fortawesome/free-solid-svg-icons';

import { loadProvisionalReceipt, loadDownloadNfse, sendCancelNfse, resendMultipleNfse } from '../store/thunk';
import { handleError, setIsNfSendModalOpen, setSelectedSingleNfWithErrors, setSelectedNfWithErrors, setSelectedNfWithErrorsPaymentDateTime, clearNfWithErrors, clearPageNfWithErrors } from '../store/actions';
import { formatFilters } from '../../../helpers/formatters';
import { convertList } from '../../../helpers/converters';
import { relativePercentage } from '../../../helpers/common';
import { loadFilters } from '../../../components/Filter/store/thunk';
import { loadSingleTransaction } from 'pages/Transactions/store/thunk';
import { setServiceOrderId } from '../../../components/ServiceOrder/store/actions';
import { toggleServiceOrder } from '../../OperationalHistory/store/actions';
import { setClientId } from '../../ClientRegistration/components/steps/BasicInfo/store/actions';
import { setEstablishmentId } from '../../BusinessInfo/store/actions';
import { setInvoiceId, handleUpdateInvoice } from '../../ClientInvoices/store/actions';
import { handleRegistration, setTransactionDetails } from '../../Transactions/store/actions';
import { setSourceId } from '../store/actions';

import { setBaseAPI } from '../../../services';

import { inputs } from './resources';

const headers = [
  { title: 'Número da nota', field: 'nfseNumber', sortable: false },
  { title: 'Série e N˚(RPS)', field: 'rpsSerieAndNumber', sortable: false },
  { title: 'Emissão', field: 'nfDateTime', sortable: false },
  { title: 'Competência', field: 'paymentDateTimeView', sortable: false },
  { title: 'Status', field: 'nfseStatus', sortable: true, defaultSort: 'desc' },
  { title: 'Valor', field: 'amountView', sortable: false },
  { title: 'Ações', field: '', sortable: false }
];

export default function ProvisionalReceipt() {
  const dispatch = useDispatch();

  const width = window.innerWidth;
  const isMobile = width < 1024;

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { establishments } = useSelector(state => state.login);
  const { modal } = useSelector(state => state.operationalHistory);
  const { isUpdatingInvoice } = useSelector(state => state.clientInvoices);
  const { registration, submit: { transactionTypeId } } = useSelector(state => state.transactions);
  const { selects, qp, isSearching, isFilterButtonClicked } = useSelector(state => state.filters);
  const { startDate, endDate } = useSelector(state => state.dateFilter);
  const { list, total, isLoading, error, selectedNfWithErrors, selectedNfWithErrorsPaymentDateTime, isSendNfModalOpen } = useSelector(state => state.provisionalReceipt);

  const filters = concat(inputs, formatFilters(['nfseStatus'], selects));
  const convertedList = convertList(['nfseStatus'], filters, list);

  const [isOpenInvoiceServiceContract, setIsOpenInvoiceServiceContract] = useState(false);
  const [calculatedInvoiceDateTime, setCalculatedInvoiceDateTime] = useState('');
  const [provisionalReceiptResendStartTime, setProvisionalReceiptResendStartTime] = useState(-1);
  const [provisionalReceiptResendTime, setProvisionalReceiptResendTime] = useState(-1);
  const [confirmResendNfse, setConfirmResendNfse] = useState(false);

  const [provisionalReceiptPage, setProvisionalReceiptPage] = useState(1);
  const provisionalReceiptPageSize = 10;
  const provisionalReceiptPageCount = Math.ceil(total / provisionalReceiptPageSize);

  const handleProvisionalReceiptList = ({ page, qp }) => {
    const extraProps = {
      startDate,
      endDate
    }

    if(establishmentId) {
      dispatch(loadProvisionalReceipt({ userId, establishmentId, page, pageSize: provisionalReceiptPageSize, qp, extraProps }));
    }
  }

  useEffect(() => {
    if(provisionalReceiptResendTime > 0) {
      setTimeout(() => {
        setProvisionalReceiptResendTime(provisionalReceiptResendTime - 1);
      }, 1000);
    }
  }, [provisionalReceiptResendTime]);

  useEffect(() => {
    if(provisionalReceiptResendTime == 0) {
      setProvisionalReceiptResendStartTime(-1);
      setProvisionalReceiptResendTime(-1);
      handleProvisionalReceiptList({ page: provisionalReceiptPage, qp });
      dispatch(setIsNfSendModalOpen(false));
    }
  }, [provisionalReceiptResendTime]);

  useEffect(() => {
    dispatch(handleError({ error: null, transactionId: null }));
  }, []);

  useEffect(() => {
    if(startDate !== 'Invalid date' && endDate !== 'Invalid date') {
      handleProvisionalReceiptList({ page: 1, qp });
      dispatch(loadFilters(userId, establishmentId));
    }
  }, [establishmentId, startDate, endDate]);

  useEffect(() => {
    handleProvisionalReceiptList({ page: provisionalReceiptPage, qp });
  }, [provisionalReceiptPage]);

  useEffect(() => {
    setProvisionalReceiptPage(1);
  }, [establishmentId]);

  useEffect(() => {
    if(isSearching) {
      setProvisionalReceiptPage(1);
    }
  }, [isSearching]);

  useEffect(() => {
    if(isFilterButtonClicked) {
      setProvisionalReceiptPage(1);
    }
  }, [isFilterButtonClicked]);

  useEffect(() => {
    if(isSearching || establishmentId) {
      dispatch(clearNfWithErrors());
    }
  }, [isSearching, establishmentId]);

  useEffect(() => {
    const list = convertedList
      ?.filter(item => selectedNfWithErrors?.includes(item?.sourceId))
      ?.map(item => {
        return {
          sourceId: item?.sourceId,
          paymentDateTime: item?.paymentDateTime,
          rpsNumber: item?.rpsNumber,
          rpsSeries: item?.rpsSeries
        }
      });

    const uniqueList = uniqBy([...selectedNfWithErrorsPaymentDateTime, ...list], function(e) {
      return e.sourceId;
    });

    const updatedList = uniqueList
      ?.filter(item => selectedNfWithErrors?.includes(item?.sourceId));

    dispatch(setSelectedNfWithErrorsPaymentDateTime(updatedList));
  }, [selectedNfWithErrors]);

  const getNfseStatus = item => {
    if(!!item?.nfCancelProtocol && +item?.nfseStatusNumber == 2) {
      return(
        <>
          Emitada com sucesso
          <br />
          <Typography variant="caption" color="textSecondary">Cancelamento rejeitado</Typography>
        </>
      );
    }

    return item?.nfseStatus;
  }

  const getEstablishmentId = establishmentUuid => {
    const establishmentId = establishments
      ?.find(establishment => establishment?.uuid == establishmentUuid)
      ?.establishmentId;

    return establishmentId || '';
  }

  const getClientId = sourceId => {
    if(sourceId) {
      return sourceId?.slice(0, sourceId?.length - 14);
    }

    return '';
  }

  const getInvoiceDateTime = sourceId => {
    if(sourceId) {
      return sourceId?.slice(sourceId?.length - 14, sourceId?.length);
    }

    return '';
  }

  const handleTransactionRegistration = async (status, modalType) => {
    dispatch(handleRegistration(status, modalType));
  }

  const selectTransactionType = async (categorySourceId, clientId, sourceId, establishmentId) => {
    dispatch(setSourceId(sourceId));

    if(categorySourceId == 1) {
      await dispatch(setServiceOrderId(sourceId, userId, establishmentId));
      await dispatch(toggleServiceOrder(true));
    }

    if(categorySourceId == 2) {
      await dispatch(setClientId(clientId));
      await dispatch(setEstablishmentId({ establishmentId }));
      await dispatch(setInvoiceId(getInvoiceDateTime(sourceId)));
      await setBaseAPI(userId, establishmentId);
      await dispatch(handleUpdateInvoice(true));
    }

    if(categorySourceId == 3) {
      const singleTransactionData = await dispatch(loadSingleTransaction(userId, establishmentId, sourceId));

      if(singleTransactionData) {
        const { transactionTypeId } = singleTransactionData;

        await handleTransactionRegistration(true, +transactionTypeId).then(async () => {
          await dispatch(setTransactionDetails({ ...singleTransactionData }));
        });
      }
    }

    if(categorySourceId == 8) {
      await dispatch(setClientId(clientId));
      setCalculatedInvoiceDateTime(getInvoiceDateTime(sourceId));
      setIsOpenInvoiceServiceContract(true);
    }
  }

  const onSelectRow = row => {
    const { sourceId } = row;
    dispatch(setSelectedSingleNfWithErrors(sourceId));
  }

  const onSelectAllRows = () => {
    const isAllRowsChecked = convertedList
      ?.filter(item => +item?.nfseStatusNumber == 4)
      ?.map(item => item?.sourceId)
      ?.every(item => selectedNfWithErrors?.includes(item));

    const list = convertedList
      ?.filter(item => +item?.nfseStatusNumber == 4)
      ?.map(item => item?.sourceId);

    if(isAllRowsChecked) {
      return dispatch(clearPageNfWithErrors(list));
    }

    dispatch(setSelectedNfWithErrors([...new Set(list)]));
  }

  return(
    <>
      {isSendNfModalOpen && (
        <Modal
          title="Deseja reenviar essas notas fiscais?"
          open={isSendNfModalOpen || (provisionalReceiptResendTime > 0)}
          onClose={() => provisionalReceiptResendTime > 0 ? null : dispatch(setIsNfSendModalOpen(false))}
        >
          {(provisionalReceiptResendTime > 0) ? (
            <Grid container spacing={2}>
              <Grid xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography color="primary">
                  Notas em processo de reenvio
                </Typography>
                {(provisionalReceiptResendTime > 0) && (
                  <LoaderLabel value={relativePercentage(provisionalReceiptResendTime, provisionalReceiptResendStartTime)}>
                    {provisionalReceiptResendTime}
                  </LoaderLabel>
                )}
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid xs={12} style={{ padding: 5 }}>
                <Typography color="primary">
                  <b>Total: {selectedNfWithErrorsPaymentDateTime?.length}</b>
                </Typography>
                <Typography color="primary">
                  Notas fiscais: {selectedNfWithErrorsPaymentDateTime?.map(item => `${item?.rpsNumber > 0 ? `${item?.rpsSeries}-${item?.rpsNumber}` : ''}`.trim())?.join(', ')}
                </Typography>
                {selectedNfWithErrorsPaymentDateTime?.some(item => moment(moment.now()).diff(moment(item?.paymentDateTime, 'YYYY-MM-DD'), 'days') > 5) && (
                  <div style={{ margin: '15px 0 15px 0' }}>
                    <WarningMessage message="Atenção! Mais de 5 dias se passaram desde o pagamento, fique atento a uma possível cobrança de multa por parte da prefeitura." />
                    <FormControlLabel
                      labelPlacement="end"
                      label={
                        <Typography color="primary">
                          Entendi
                        </Typography>
                      }
                      control={
                        <Checkbox
                          value={confirmResendNfse}
                          onChange={event => setConfirmResendNfse(event.target.checked)}
                        />
                      }
                    />
                  </div>
                )}
              </Grid>
              <Grid xs={6} style={{ padding: 5 }}>
                <Button
                  fullWidth
                  color="error"
                  onClick={() => dispatch(setIsNfSendModalOpen(false))}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid xs={6} style={{ padding: 5 }}>
                <Button
                  disabled={
                    selectedNfWithErrorsPaymentDateTime?.some(item => moment(moment.now()).diff(moment(item?.paymentDateTime, 'YYYY-MM-DD'), 'days') > 5) &&
                    !confirmResendNfse
                  }
                  loading={isLoading}
                  fullWidth
                  color="primary"
                  onClick={() => {
                    const nfSourceIds = selectedNfWithErrorsPaymentDateTime
                      ?.sort((a, b) => moment(a?.paymentDateTime, 'DD-MM-YYYY HH:mm:ss').diff(moment(b?.paymentDateTime, 'DD-MM-YYYY HH:mm:ss')))
                      ?.map(item => item?.sourceId);

                    dispatch(resendMultipleNfse(userId, establishmentId, nfSourceIds)).then(() => {
                      const resendTime = selectedNfWithErrorsPaymentDateTime?.length > 10
                        ? selectedNfWithErrorsPaymentDateTime?.length * 0.5
                        : selectedNfWithErrorsPaymentDateTime?.length;

                      setProvisionalReceiptResendStartTime(resendTime);
                      setProvisionalReceiptResendTime(resendTime);
                    });
                  }}
                >
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          )}
        </Modal>
      )}
      <Grid container style={{ backgroundColor: '#fCD40A', border: '1px solid #38A8D8', borderRadius: 10, padding: 15, marginBottom: 10 }}>
        <Grid xs={12}>
          <Typography color='primary'>
            <b>
              A migração de emissor de nota fiscal foi realizada no sistema da Jump! Agora todas as notas ficais geradas até o dia 04/11 só poderão ser visualizadas no seu portal da prefeitura!
            </b>
          </Typography>
        </Grid>
      </Grid>
      <Paper>
        {modal && (
          <ServiceOrder modal={modal} />
        )}
        {(registration && +transactionTypeId == 1) && (
          <Receipt isOpen={registration} />
        )}
        {(registration && +transactionTypeId == 2) && (
          <Payment isOpen={registration} />
        )}
        {isUpdatingInvoice && (
          <Invoice
            isOpen={isUpdatingInvoice}
            establishmentId={establishmentId}
          />
        )}
        {isOpenInvoiceServiceContract && (
          <InvoiceServiceContract
            isOpenInvoiceServiceContract={isOpenInvoiceServiceContract}
            setIsOpenInvoiceServiceContract={setIsOpenInvoiceServiceContract}
            calculatedInvoiceDateTime={calculatedInvoiceDateTime}
            isInvoicePaid={true}
          />
        )}
        <ProvisionalReceiptError
          open={!!error}
          error={error}
          page={provisionalReceiptPage}
          pageSize={provisionalReceiptPageSize}
          qp={qp}
          extraProps={{ startDate, endDate }}
        />
        {isMobile ? (
          <FilterMobile
            filter
            hasSearchTerm
            linkButtonText="Configurações das notas fiscais"
            hasLinkButton="/configuracoes-de-notas-fiscais"
            hasNfSendButton
            filters={filters}
            handlePageRequest={handleProvisionalReceiptList}
          />
        ) : (
          <Filter
            filter
            hasSearchTerm
            linkButtonText="Configurações das notas fiscais"
            hasLinkButton="/configuracoes-de-notas-fiscais"
            hasNfSendButton
            filters={filters}
            handlePageRequest={handleProvisionalReceiptList}
          />
        )}
        {isLoading ? (
          <>
            <TableContainer>
              <Table>
                <TableHead style={{ backgroundColor: '#1E5168' }}>
                  <TableRow>
                    {headers.map(item => {
                      return(
                        <TableCell style={{ color: '#FFFFFF' }}>{item.title}</TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
            <div style={{ display: 'flex', justifyContent: 'center', padding: 20 }}>
              <CircularProgress color='primary' size={30} />
            </div>
          </>
        ) : (
          <>
            {convertedList?.length == 0 && (
              <>
                <TableContainer>
                  <Table>
                    <TableHead style={{ backgroundColor: '#1E5168' }}>
                      <TableRow>
                        {headers.map(item => {
                          return(
                            <TableCell style={{ color: '#FFFFFF' }}>{item.title}</TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>
                <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
                  <Typography color='primary' variant='body2'>Não foram encontrados itens nessa tabela</Typography>
                </div>
              </>
            )}
            {convertedList?.length > 0 && (
              <TableContainer>
                <Table>
                  <TableHead style={{ backgroundColor: '#1E5168' }}>
                    <TableRow>
                      <TableCell style={{ textAlign: 'center' }}>
                        {convertedList?.filter(item => +item?.nfseStatusNumber == 4)?.length > 0 && (
                          <Checkbox
                            style={{ color: '#FFFFFF' }}
                            checked={
                              convertedList
                                ?.filter(item => +item?.nfseStatusNumber == 4)
                                ?.map(item => item?.sourceId)
                                ?.every(item => selectedNfWithErrors?.includes(item))
                            }
                            onChange={onSelectAllRows}
                          />
                        )}
                      </TableCell>
                      {headers.map(item => {
                        return(
                          <TableCell style={{ textAlign: 'center', color: '#FFFFFF' }}>{item.title}</TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {convertedList?.map(item => {
                      return(
                        <TableRow>
                          <TableCell style={{ textAlign: 'center' }}>
                            <Checkbox
                              color='primary'
                              disabled={item?.nfseStatusNumber != 4}
                              checked={selectedNfWithErrors?.includes(item?.sourceId)}
                              onChange={() => onSelectRow(item)}
                            />
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>{item?.nfseNumber}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}>{item?.rpsSerieAndNumber}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}>{item?.nfDateTime}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}>{item?.paymentDateTimeView}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}>{getNfseStatus(item)}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}>{item?.amountView}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {(+item?.nfseStatusNumber == 4) && (
                              <IconButton
                                size="medium"
                                onClick={() => {
                                  dispatch(
                                    handleError({
                                      error: item && item?.nfResult,
                                      paymentDateTime: item?.paymentDateTime,
                                      transactionId: item?.sourceId,
                                      establishmentId: getEstablishmentId(item?.establishmentUuid)
                                    })
                                  )
                                }}
                              >
                                <FontAwesomeIcon
                                  size="xs"
                                  color="#F66D6E"
                                  icon={faExclamationCircle}
                                />
                              </IconButton>
                            )}
                            <IconButton
                              size="medium"
                              onClick={() => selectTransactionType(item?.categorySourceId, getClientId(item?.sourceId), item?.sourceId, getEstablishmentId(item?.establishmentUuid))}
                            >
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                                color="#2CA9D6"
                                size="xs"
                              />
                            </IconButton>
                            {(+item?.nfStatus == 2) && (
                              <IconButton
                                size="medium"
                                onClick={() => dispatch(loadDownloadNfse(userId, getEstablishmentId(item?.establishmentUuid), item?.sourceId))}
                              >
                                <FontAwesomeIcon
                                  icon={faDownload}
                                  color="#2CA9D6"
                                  size="xs"
                                />
                              </IconButton>
                            )}
                            {(+item?.nfseStatusNumber == 2) && (
                              <ButtonConfirmation
                                iconButton={
                                  <FontAwesomeIcon
                                    size="xs"
                                    color="#F66D6E"
                                    icon={faTimesCircle}
                                  />
                                }
                                modalTitle="Cancelar Nota Fiscal"
                                modalText="O cancelamento de nota fiscal não pode ser revertido, têm certeza que deseja realizar essa situação?"
                                modalButtons={[
                                  {
                                    color: 'success',
                                    title: 'Confirmar',
                                    onClick: () => {
                                      const params = {
                                        page: provisionalReceiptPage,
                                        pageSize: provisionalReceiptPageSize,
                                        qp,
                                        extraProps: { startDate, endDate }
                                      }

                                      dispatch(sendCancelNfse(userId, getEstablishmentId(item?.establishmentUuid), item?.uuid, params));
                                    }
                                  }
                                ]}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
        <Grid
          container
          xs={12}
          style={{ padding: 10, display: 'flex', justifyContent: 'flex-end' }}
        >
          <Pagination
            color="primary"
            variant="outlined"
            shape="rounded"
            count={provisionalReceiptPageCount}
            page={provisionalReceiptPage}
            onChange={(_, page) => setProvisionalReceiptPage(page)}
          />
        </Grid>
      </Paper>
    </>
  );
}